.left-bar-smart-client-icons-animation {
    opacity: 0;
    transition: opacity .4s ease;
    position: relative;

    .icon-inner-wrapper {
        max-height: 0;
        transition: max-height .3s ease;
    }

    &:after {
        content: '';
        position: absolute;
        left: -6px;
        bottom: 2px;
        top: -44px;
        margin: auto;
        width: 4px;
        background-color: rgba(0,94,185, .2);
    }

    .dark {
        &:after {
            background-color: #1e1e1e;
        }
    }
}

.left-bar-icon-description {
    opacity: 0;
    transition: opacity .4s ease, max-width .3s ease;
   // position: absolute;
   // left: 44px;
    max-width: 0;
    //overflow: hidden;

    .initialLoad & {
        opacity: 1 !important;
        max-width: calc(100% - 50px) !important;
    }
}

.left-bar-transition {
    &-enter {
            //overflow: hidden;
        opacity: 0;
    }

    &-enter-active {
            //overflow: hidden;
        max-width: calc(100% - 50px);
        opacity: 1;
    }

    &-enter-done {
            max-width: calc(100% - 50px);

        height: 100%;
        display: flex;
        align-items: center;

        opacity: 1;
    }

    &-exit {
            max-width: 0;
        opacity: 1;
    }

    &-exit-active {
            max-width: 0;
        opacity: 0;
    }
}
