.cw-wait-cursor {
    transform: translate(-50%, -50%);
    text-align: center;
    position: fixed;
    z-index: 500;
    top: 50%;
    left: 50%;
    background-color: rgba(127, 127, 127, .3);
    backdrop-filter: blur(15px);
    padding: 10px;
    border-radius: 2px;
    transition: background-color .5s ease;

    &.show-text {
        padding: 10px;
        background-color: rgba(127, 127, 127, 0.7);
    }

    &.show-progress {
        padding: 25px 10px 10px 10px;
        background-color: rgba(127,127,127,.7);
    }

    &.show-text > .cw-wait-cursor-wrapper {
        width: 234px;
    }

    .cw-wait-cursor-wrapper {
        .cw-wc-image {
            margin: 0 auto;
            background-repeat: no-repeat;
            height: 70px;
            width: 70px;
            background-image: url('https://tsimg.cloud/static/chaynsweb/wait-cursor/icon_animated_new_v4.apng');
            background-size: contain;
        }

        .cw-wc-text {
            line-height: 20px;
            text-align: left;
            margin-top: 12px;
            max-width: 400px;
            padding: 5px;
            color: white;
        }

        .cw-wait-cursor-progress-container {
            margin-top: 25px;
            padding: 5px;
            width: 224px;
            text-align: left;

            .cw-wait-cursor-progress-wrapper {
                background-color: var(--chayns-color--003);
                position: relative;
                overflow: hidden;
                border-radius: 2px;
                height: 10px;

                .cw-wait-cursor-progress {
                    transition: all .3s;
                    background-color: #fff;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }
            }

            .cw-wait-cursor-progress-text {
                font-size: 85%;
                color: #fff;
            }
        }
    }
}

.cw-wait-cursor-animation {
    &-enter {
        opacity: 0;
    }

    &-enter-active {
        opacity: 1;
        transition: opacity 0.2s ease;
    }

    &-exit {
        opacity: 1;
    }

    &-exit-active {
        opacity: 0;
        transition: opacity 0.2s ease;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}
