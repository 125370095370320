.console {
  position: fixed;
  z-index: 2;
  bottom: 0;
  right: 0;
  width: 500px;
  height: 200px;
  background-color: black;
  color: white;

  &__element-wrapper {
    position: absolute;
    top: 0;
    height: 170px;
    width: 100%;
  }

  &__element {
    border-bottom: solid 1px white;
    overflow: hidden;
    padding: 0 4px;
    word-break: break-all;
  }

  &__content {
    max-width: 400px;
    float: left;
    -ms-user-select: text;
  }

  &__date {
    float: right;
  }


  &__input {
    position: absolute;
    bottom: 0;
    height: 10%;
    width: 96%;
    background-color: #2D2D2D !important;
    color: white !important;
    overflow: hidden;
  }
}

.activationElement {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
}